<template>
  <section class="m-favorite m-scroll">

    <!-- 头部导航 -->
    <m-header title="Submit"> </m-header>


    <!-- 商品信息 -->

    <div v-for="(goods,index) in goodsList" :key="index">
      <div class="goods-wrap van-hairline--top" >
        <div class="avatar"><img :src="goods.goods_sku_pic_url" alt="img"></div>
        <div class="info ">
          <div class="title">
            <p class="van-ellipsis">{{goods.goods_ename}}</p>
            <span>x{{goods.disabled?goods.disabled:1}}</span>
          </div>
          <span>{{goods.sku_ename}}</span>
          <p>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{goods.goods_price}}</p>
        </div>
      </div>
      <!-- <div v-show="module == 2" class="goods-number">
        <span>Number</span>
        <van-stepper v-model="number[index]" :max="goods.goods_number" />
      </div> -->
    </div>
    


    <!-- 选择售后类型 -->
    <div v-show="module == 1" class="module-1">

        <div @click="handleType(1)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/return.png" alt="return">
            <span>Refunds</span>
          </div>
          <div class="right">
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>

        <!-- <div @click="handleType(2)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/icon_bufa@2x.png" alt="supply">
            <span>Missing Items</span>
          </div>
          <div class="right">
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>

        <div @click="handleType(3)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/exchange.png" alt="exchange">
            <span>Exchange</span>
          </div>
          <div class="right">
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div> -->

        <div @click="handleType(4)" class="module-cell">
          <div class="left">
            <img src="@/assets/aftersale/refund.png" alt="refund">
            <span>Only refund</span>
          </div>
          <div class="right">
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>

    </div>

    <div v-show="module == 2" class="module-2">

  

      <div @click="picker_1 = true" class="reason m-padding van-hairline--bottom">
        <div class="left">Reason</div>
        <div class="right">
          <span>{{form.reason}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </div>
      
      <div class="content m-padding">
        <textarea v-model="form.describe"  placeholder="Please describe the specific reasons for applying for after-sales, using text + pictures to describe it."></textarea>
      </div>
      
      <div class="upload-box m-padding">
        <m-uploads :imgs.sync="imgs" file-name="aftersale" :max="3" text="Max 3 Pictures"></m-uploads>
      </div>

      <div class="tips-box m-padding">
        <div class="tips-method">
          <!-- <p style="margin-top:15px">
            <span>Refund amount</span>
            <span>${{(orderPrice - 0).toFixed(2)}}</span>
          </p> -->
          <!-- <p>*Cannot be modified, up to {{(orderPrice - 0).toFixed(2)}}，including delivery</p> -->
          <van-collapse v-model="activeNames" style="width: 100%;">
              <van-collapse-item name="1">
                <template #title>
                  <div class="priceBox">
                    <div class="priceTitle">
                      Refund amount 
                    </div>
                    <div class="priceContent">
                      {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{(orderPrice - 0).toFixed(2)}}</div> 
                    </div>
                </template>
                 <p style="font-size: 12px;">Product Price: {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_goods_price}},Shipping fee: {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_freight_price}}</p>
                 <p style="font-size: 12px;"> Tax: {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_tax_price}},Credits in Wallet: {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_wallet_price}}</p>
              </van-collapse-item>
          </van-collapse>
        </div>
        <div class="tips-method">
          <p>
              <span>Refund method</span>
              <span>Original payment Refund</span>
            </p>
          <p>*Original payment refund: online payment only supports original refund</p>
        </div>
      </div>

      <div class="cell-box m-padding" @click="picker_2 = true" v-show="form.type != 4">
        <span>Return method</span>
        <div>
          <span>{{form.returnType}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </div>

      <div class="person">
        <div class="cell-box cell-margin m-padding">
          <span>Contact person</span>
          <input v-model="contacts" type="text" placeholder="Name">
        </div>
        <div class="cell-box cell-margin m-padding">
          <span>Contact details</span>
          <input  v-model="contact_number" type="number" placeholder="Phone or E-mail">
        </div>
      </div>

      <div class="address" v-show="form.type != 4 && form.returnType == 'Mail to Mooby warehouse'">
        <p>Shipping Address</p>
        <p>
          <img src="@/assets/aftersale/icon_address.png" alt="address">
          <span>MoobyYoho</span>
          <span>7786806480</span>
        </p>
        <div>
          <p>11121 Horseshoe Way Unit 133，Richmond，BC V7A 5G7</p>
        </div>
      
      </div>

    </div>

     <div class="customer">
        <van-button v-show="module == 2" @click="onSubmit()" block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Submit application</van-button>
        <p v-show="module == 2" class="btn-tips">*After submitting the application, the after-sales specialist may communicate with you over the phone.</p>
        <van-button v-show="module != 2" block round plain color="#3284FA" @click="$router.push('/en/customer')">Contact Customer Service</van-button>
      </div>   
      

      <!-- 选择售后原因 -->
      <van-action-sheet v-model="picker_1" :actions="reasons"    cancel-text="Cancel" close-on-click-action @cancel="picker_1 = false" @select="handleReason" />
      <!-- 选择退货方式 -->
      <van-action-sheet v-model="picker_2" :actions="returnType" cancel-text="Cancel" close-on-click-action @cancel="picker_2 = false" @select="handleReturnType" />
    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import MUploads  from '@/components/zh/m-uploads.vue'
import { aftersale } from '@/api/zh/cart.js'
export default {
  name:'AfterSaleSubmit',
  components:{ MHeader, MUploads },
  data(){
    return {
      goodsList: [],
      orderPrice: 0,
      goodsPrice: 0,
      activeNames: [],
      module: 1,
      form: {
        type: 0,
        reason: 'Select Reason',
        describe: '',
        returnType: 'Self-delivery',
      },

      picker_1:false,
      reasons:[
        {name:'Wrong or missed goods'},
        {name:'No reason to return within seven days'},
        {name:'Quality issues'},
        {name:'Only Refund'},
        {name:'Other'}
      ],

      picker_2:false,
      returnType:[ {name:'Self-delivery'},{name:'Mail to Mooby warehouse'}],
      number: [],
      imgs: [],
      contacts: '',
      contact_number: '',
      isNotBreak: false,
      orderDetail: {order_logistics:''}
    }
  },

  beforeRouteLeave (to, from, next) {
    if(this.module == 2 && !this.isNotBreak){
      this.module = 1
      next(false)
    }else{
      next()
    }
  },

  methods:{

    // 选择售后类型
    handleType(index){
      this.form.type = index
      this.module    = 2
    },

    // 选择售后原因
    handleReason(it){
      this.form.reason = it.name
    },
    // 选择退货方式
    handleReturnType(it){
      this.form.returnType = it.name
    },

    // 上传凭证
    uploadImg(file) {
      console.log(file)
    },
    

    // 点击提交
    onSubmit(){

      let params = {
        order_id: this.$route.query.order,
        mode:this.form.type,
        reason:this.form.reason,
        describe: this.form.describe,
      }

      console.log(this.form)

      let object = {  }
      JSON.parse(this.$route.query.goods).forEach((e)=>{
        object[e.split(":")[0]] = e.split(":")[1]
      })
      params.after_sale_goods_info = object
      // params.number = this.number
      
      if(this.form.reason == 'Select Reason') {
        this.$notify({ type: 'warning', message: 'please select reason' });
        return false
      }
      if(this.form.describe == '') {
        this.$notify({ type: 'warning', message: 'please describe the specific reasons' });
        return false
      }
      if(this.form.returnType == 'Self-delivery') {
        params.return_mode = 1
        
      }else {
        params.return_mode = 2
      }

      // if(this.contacts == '') {
      //   this.$notify({ type: 'warning', message: 'Please enter your name' });
      //   return false
      // }else{
        params.contacts = this.contacts
      // }
      // if(this.contact_number == '') {
      //   this.$notify({ type: 'warning', message: 'Please enter your phone or email' });
      //   return false
      // }else {
        params.contact_number = this.contact_number
      // }

      let pic = []
      if(this.imgs.length != 0){
        this.imgs.forEach(e => {
          pic.push(e.pic_id)
        })
        params.describe_pic = pic.join(',')
      }
      aftersale(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.isNotBreak = true
          this.$router.push( {path: '/en/order/4?back=account'} )
        }
      })
    }
  },
  computed: {
    previewSize: {
      set(){
        return '82px'
      },
      get(){
        let clientWidth = document.body.clientWidth
        return `${82/375*clientWidth}px`
      }
    }
  },

  created() {
        const order = JSON.parse(window.sessionStorage.getItem('aftersale'));
        this.orderDetail = JSON.parse(window.sessionStorage.getItem('aftersale'))
    this.orderPriceCount = JSON.parse(window.sessionStorage.getItem('aftersalePrice'))
    this.orderPrice = this.orderPriceCount.refund_amount;
    order.order_goods.forEach(e => {
       JSON.parse(this.$route.query.goods).forEach(el=>{
          if(e.rec_id == el.split(":")[0]) {
            this.goodsList.push(e)
          }
        })
    })
  }
}
</script>

<style lang="less" scoped>
@import './submit.less';
/deep/ .van-hairline--top-bottom::after,.van-hairline-unset--top-bottom::after {
  border: none;

}
/deep/ .van-collapse-item {
    .van-cell {
      padding-left: 0;
      padding-right: 0;
      .priceBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .priceContent {
          color: red;
        }
      }
    }
  }
</style>